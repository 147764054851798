<script setup lang="ts">
defineProps<{
  title?: string
  subTitle?: string
}>()

const form = ref()
const top = ref('')
const left = ref('')
const isRendered = ref(false)
const isVisible = defineModel('isVisible', {
  default: false,
})
const mo = new MutationObserver(updatePosition)

function updateOnRendered() {
  if (isRendered.value)
    updatePosition()
}

function updatePosition() {
  top.value = `${
    window.innerHeight / 2 - form.value.getBoundingClientRect().height / 2
  }px`
  left.value = `${
    window.innerWidth / 2 - form.value.getBoundingClientRect().width / 2
  }px`
}

function observe() {
  mo.observe(form.value, {
    attributes: true,
    subtree: true,
    childList: true,
  })
}

function unMount() {
  setTimeout(() => {
    isRendered.value = false
  }, 300)
}

function start() {
  if (form.value) {
    updatePosition()
    window.addEventListener('resize', updateOnRendered, true)
    observe()
  }
}

function stop() {
  unMount()
  mo.disconnect()
  removeEventListener('resize', updateOnRendered)
}

watch(
  () => form.value,
  () => {
    if (form.value) {
      start()
    }
  },
  { once: true },
)

watch(
  () => isVisible.value,
  () => {
    if (isVisible.value) {
      isRendered.value = true
      start()
    }
    else {
      stop()
    }
  },
)

// Фикс рендера при hmr
onBeforeUnmount(() => {
  isVisible.value = false
  isRendered.value = false
  stop()
})

// Detect Esc key press
function handleEscKey(event: KeyboardEvent) {
  if (event.key === 'Escape') {
    isVisible.value = false
  }
}

onMounted(() => {
  window.addEventListener('keydown', handleEscKey)
})

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleEscKey)
})
</script>

<template>
  <div v-show="isRendered" class="fixed z-50 top-0 left-0 w-screen h-screen">
    <ClientOnly>
      <Transition name="background">
        <div
          v-if="isVisible"
          class="w-full h-full backdrop-blur-md"
          @click="isVisible = false"
        />
      </Transition>
      <div
        ref="form"
        :style="`top: ${top}; left: ${left}`"
        class="fixed flex flex-col gap-4 py-5 px-0 md:px-5 rounded-lg bg-white shadow-md"
        :class="isVisible ? 'show-form' : 'hide-form'"
      >
        <div class="absolute top-0 right-0 pr-5 pt-5">
          <img
            src="/assets/icons/close.svg"
            class="h-5 w-5 cursor-pointer hover:opacity-50 duration-150"
            @click="isVisible = false"
          >
          <!-- <NuxtImg src="/icons/close.svg" class="h-5 w-5 cursor-pointer hover:opacity-50 duration-150" @click="isVisible = false" /> -->
        </div>
        <div class="py-10 px-5 lg:px-10">
          <div class="flex flex-col gap-2 mb-4">
            <h1 class="text-h3" v-html="title" />
            <p class="text-primary max-w-[666px]" v-html="subTitle" />
          </div>
          <slot />
        </div>
      </div>
    </ClientOnly>
  </div>
</template>

<style scoped>
@keyframes showForm {
  0% {opacity: 0; transform: translateY(20px);}
  100% {opacity: 1; transform: translateY(0px)}
}

@keyframes hideForm {
  0% {opacity: 1; transform: translateY(0px)}
  100% {opacity: 0; transform: translateY(20px)}
}

.background-enter-active,
.background-leave-active {
  transition: opacity 200ms ease-in-out;
}

.background-enter-from,
.background-leave-to {
  opacity: 0;
}

.show-form {
  opacity: 0;
  animation: showForm 200ms ease-in-out;
  animation-fill-mode: forwards;
}

.hide-form {
  opacity: 1;
  animation: hideForm 200ms ease-in-out;
  animation-fill-mode: forwards;
}
</style>
